import Service from './index';

export default {
  userLogin(data) {
    return Service.post('/userAccount/userLogin', data);
  },

  demoUserLogin() {
    return Service.post('/userAccount/userDemoLogin');
  },

  userLogoutStatus() {
    return Service.put('/userAccount/userLogoutStatus');
  },

  userSignup(data) {
    return Service.post('/userAccount/userSignup', data);
  },

  increaseTokenExpiryTime() {
    return Service.put('/userAccount/increaseTokenExpiryTime');
  }

}
