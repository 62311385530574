
import { toast } from 'react-toastify';
export default (msg, type, position, options={}) => toast[type]('💰 ' +  msg , {
  position: position,
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  ...options,
});
