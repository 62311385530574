import { notify } from "react-notify-toast";
import logEvent from "./logEvent";

const logEventHandler = async (userId, eventType, ClientName, reffer, Amount, WAmount) => {
  let actualEventType;
  switch (eventType) {
    case "login":
      actualEventType = "login";
      break;
    case "withdrawal":
      actualEventType = "withdrawal";
      break;
    case "deposit":
      actualEventType = "deposit";
      break;
    case "signup":
      actualEventType = "signup";
      break;
    default:
      actualEventType = eventType;
      break;
  }

  const payload = {
    userId: userId,
    eventType: actualEventType,
    ClientName: ClientName,
    reffer: reffer,
    DepositAmount: Amount ? Amount : 0,
    WithdrawalAmount: WAmount ? WAmount : 0
  };

  console.log("Payload:", payload); // Debug payload

  try {
    const response = await logEvent.LogEventPost(payload);
    if (response.status === 200) {
      console.log("Response:", response);
    }
  } catch (error) {
    console.log("Error:", error);

    if (error.response && error.response.status === 400) {
      notify(error.response.data, "error", "top-left");
    } else {
      notify("Error. Please try again later.", "error", "top-left");
    }
  }
};

export default logEventHandler;